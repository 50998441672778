<template>
  <!-- Footer Start -->
  <div
    class="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-4">Our Office</h4>
          <p class="mb-2">
            <i class="fa fa-map-marker-alt me-3"></i>Kicukiro, Kigali, RWANDA
          </p>
          <p class="mb-2">
            <i class="fa fa-phone-alt me-3"></i>+250789 326 245
          </p>
          <p class="mb-2">
            <i class="fa fa-envelope me-3"></i>ganzatambaheritier24@gmail.com
          </p>
          <div class="d-flex pt-2">
            <a
              class="btn btn-square btn-outline-light rounded-circle me-2"
              href=""
              ><i class="fab fa-twitter"></i
            ></a>
            <a
              class="btn btn-square btn-outline-light rounded-circle me-2"
              href=""
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a
              class="btn btn-square btn-outline-light rounded-circle me-2"
              href=""
              ><i class="fab fa-youtube"></i
            ></a>
            <a
              class="btn btn-square btn-outline-light rounded-circle me-2"
              href=""
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-4">Services</h4>
          <a class="btn btn-link" href="">Financial Planning</a>
          <a class="btn btn-link" href="">Cash Investment</a>
          <a class="btn btn-link" href="">Financial Consultancy</a>
          <a class="btn btn-link" href="">Business Loans</a>
          <a class="btn btn-link" href="">Business Analysis</a>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-4">Quick Links</h4>
          <a class="btn btn-link" href="">About Us</a>
          <a class="btn btn-link" href="">Contact Us</a>
          <a class="btn btn-link" href="">Our Services</a>
          <a class="btn btn-link" href="">Terms & Condition</a>
          <a class="btn btn-link" href="">Support</a>
        </div>
        <div class="col-lg-3 col-md-6">
          <h4 class="text-white mb-4">Newsletter</h4>
          <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
          <div class="position-relative w-100">
            <input
              class="form-control bg-white border-0 w-100 py-3 ps-4 pe-5"
              type="text"
              placeholder="Your email"
            />
            <button
              type="button"
              class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
            >
              SignUp
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End -->

  <!-- Copyright Start -->
  <div class="container-fluid copyright py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
          &copy; <a class="border-bottom" href="#"> IKIMINA</a>, All Right
          Reserved.
        </div>
        <div class="col-md-6 text-center text-md-end">
          <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
          <!-- Designed By -->
          <!-- <a class="border-bottom" href="https://htmlcodex.com">HTML Codex</a> -->
        </div>
      </div>
    </div>
  </div>
  <!-- Copyright End -->

  <!-- Back to Top -->
  <a
    href="#"
    class="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"
    ><i class="bi bi-arrow-up"></i
  ></a>
</template>
