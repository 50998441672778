<template>
  <td>{{ id }}</td>
  <td>{{ transactionDate }}</td>
  <td>{{ transactionAmount }} RWF</td>
  <td>{{ transactionType }}</td>
  <td>{{ memberNames }}</td>
   <td>{{ transactionReason }}</td>
</template>
<script>
export default {
  props: {
    id: {
      type: String
    },
    transactionDate: {
      type: String
    },
    transactionAmount: {
      type: String
    },
    transactionType: {
      type: String
    },
    memberNames: {
      type: String
    },
    transactionReason: {
      type: String
    }
  },
  methods: {
    updateInvoice () {
      alert(this.transactionType)
    }
  }
}
</script>
