import { createStore } from 'vuex'

export default createStore({
  state: {
    memberID: '',
    names: '',
    gender: '',
    phoneNumber: '',
    email: '',
    status: '',
    seasonalShareID: '',
    savingID: '',
    savingMonthly: '',
    savingMemberNames: '',
    savingShareAmount: '',
    savingAmountPaid: '',
    savingRemainingAmount: '',
    savingFines: '',
    savingStatus: '',
    bankID: ''
  },
  getters: {
  },
  mutations: {
    setBankID (state, bankID) {
      state.bankID = bankID
    },
    setSavingID (state, savingID) {
      state.savingID = savingID
    },
    setSavingMonthly (state, savingMonthly) {
      state.savingMonthly = savingMonthly
    },
    setSavingMemberNames (state, savingMemberNames) {
      state.savingMemberNames = savingMemberNames
    },
    setSavingShareAmount (state, savingShareAmount) {
      state.savingShareAmount = savingShareAmount
    },
    setSavingAmountPaid (state, savingAmountPaid) {
      state.savingAmountPaid = savingAmountPaid
    },
    setSavingRemainingAmount (state, savingRemainingAmount) {
      state.savingRemainingAmount = savingRemainingAmount
    },
    setFines (state, savingFines) {
      state.savingFines = savingFines
    },
    setSavingStatus (state, savingStatus) {
      state.savingStatus = savingStatus
    },
    setSeasonalShareID (state, seasonalShareID) {
      state.seasonalShareID = seasonalShareID
    },
    setMemberID (state, memberID) {
      state.memberID = memberID
    },
    setMemberNames (state, names) {
      state.names = names
    },
    setGender (state, gender) {
      state.gender = gender
    },
    setPhoneNumber (state, phoneNumber) {
      state.phoneNumber = phoneNumber
    },
    setEmail (state, email) {
      state.email = email
    },
    setStatus (state, status) {
      state.status = status
    }
  },
  actions: {
  },
  modules: {
  }
})
