<template>
  <div class="container-fluid position-relative bg-white d-flex p-0">
    <!-- Spinner Start -->
    <!-- <div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div> -->
    <!-- Spinner End -->

    <!-- Sidebar Start -->
    <div class="sidebar pe-4 pb-3">
      <nav class="navbar bg-light navbar-light">
        <a href="#" class="navbar-brand mx-4 mb-3">
          <h3 class="text-primary">
            <i class="fa fa-hashtag me-2"></i>IKIMINA
          </h3>
        </a>
        <div class="d-flex align-items-center ms-4 mb-4">
          <div class="position-relative">
            <img
              class="rounded-circle"
              src="assets/img/user.jpg"
              alt=""
              style="width: 40px; height: 40px"
            />
            <div
              class="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"
            ></div>
          </div>
          <div class="ms-3">
            <h6 class="mb-0">{{ userName }}</h6>
            <span hidden>{{ userID }}</span>
          </div>
        </div>
        <div class="navbar-nav w-100">
          <router-link to="/dashboard" class="nav-item nav-link active"
            ><i class="fa fa-tachometer-alt me-2"></i>Dashboard</router-link
          >
          <router-link to="/group-members" class="nav-item nav-link"
            ><i class="fa fa-user me-2"></i>Members</router-link
          >
          <router-link to="/group-savings" class="nav-item nav-link"
            ><i class="fa fa-keyboard me-2"></i>Savings</router-link
          >
          <router-link to="/group-loans" class="nav-item nav-link"
            ><i class="fa fa-table me-2"></i>Loans</router-link
          >
          <!-- <a href="#" class="nav-item nav-link"
            ><i class="fa fa-chart-bar me-2"></i>Shares</a
          > -->
          <router-link to="/accounts" class="nav-item nav-link"
            ><i class="fa fa-th me-2"></i>Accounts</router-link
          >
          <router-link to="/banks" class="nav-item nav-link"
            ><i class="fa fa-file-alt me-2"></i>Banks</router-link
          >
          <router-link to="/reports" class="nav-item nav-link"
            ><i class="fa fa-desktop me-2"></i>Report</router-link
          >
          <router-link to="/users" class="nav-item nav-link" v-if="userRole === 'owner'"
            ><i class="fa fa-users me-2"></i>Users</router-link
          >
          <router-link to="/user-profile" class="nav-item nav-link" v-if="userRole !== 'owner'"
            ><i class="fa fa-users me-2"></i>Profile</router-link
          >
        </div>
      </nav>
    </div>
    <!-- Sidebar End -->

    <!-- Content Start -->
    <div class="content">
      <!-- Navbar Start -->
      <nav
        class="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0"
      >
        <a href="#" class="navbar-brand d-flex d-lg-none me-4">
          <h2 class="text-primary mb-0"><i class="fa fa-hashtag"></i></h2>
        </a>
        <a href="#" class="sidebar-toggler flex-shrink-0">
          <i class="fa fa-bars"></i>
        </a>
        <form class="d-none d-md-flex ms-4">
          <input
            class="form-control border-0"
            type="search"
            placeholder="Search"
          />
        </form>
        <div class="navbar-nav align-items-center ms-auto">
          <div class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <i class="fa fa-bell me-lg-2"></i>
              <span class="d-none d-lg-inline-flex">Settings</span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0"
            >
              <router-link to="/setting-savings" class="dropdown-item">
                <h6 class="fw-normal mb-0">Savings Settings</h6>
                <small>Manage Savings Settings</small>
              </router-link><br>
              <!-- <hr class="dropdown-divider" />
              <router-link to="/setting-loans" class="dropdown-item">
                <h6 class="fw-normal mb-0">Loans Settings</h6>
                <small>Manage Loans Settings</small>
              </router-link>
              <hr class="dropdown-divider" />
              <router-link to="setting-shares" class="dropdown-item">
                <h6 class="fw-normal mb-0">Shares Settings</h6>
                <small>Manage Shares</small>
              </router-link> -->
              <hr class="dropdown-divider" />
              <a href="#" class="dropdown-item text-center"
                >See all notifications</a
              >
            </div>
          </div>
          <div class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <img
                class="rounded-circle me-lg-2"
                src="assets/img/user.jpg"
                alt=""
                style="width: 40px; height: 40px"
              />
              <span class="d-none d-lg-inline-flex">{{ userName }}</span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0"
            >
              <a href="#" class="dropdown-item">My Profile</a><br>
              <a href="/billing-details" class="dropdown-item">Billing Details</a><br>
              <a href="/company" class="dropdown-item">Switch Group</a><br>
              <a href="#" class="dropdown-item" @click="logoutClick();">Log Out</a>
            </div>
          </div>
        </div>
      </nav>
      <!-- Navbar End -->
      <router-view name="dashboardRouter"></router-view>
    </div>
    <!-- Content End -->

    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"
      ><i class="bi bi-arrow-up"></i
    ></a>
  </div>
</template>
<script>
export default {
  data () {
    return {
      userID: '',
      userName: '',
      userRole: ''
    }
  },
  methods: {
    getUserData () {
      this.userID = localStorage.getItem('userID')
      this.userName = localStorage.getItem('userName')
      this.userRole = localStorage.getItem('userRole')
    },
    logoutClick () {
      localStorage.removeItem('userID')
      localStorage.removeItem('userName')
      localStorage.removeItem('companyID')
      this.$router.push('/')
    }
  },
  mounted () {
    this.getUserData()
  }
}
</script>
