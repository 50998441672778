import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomeDashboardItem from '../components/dashboardItem/HomeDashboardItem.vue'
import Dashboard from '../views/DashboardView.vue'
import AdminDashboardItem from '../views/AdminPortal.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sign-up',
    name: 'signUp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SignUp.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/ServiceView.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/CompaniesView.vue')
  },
  {
    path: '/admin-portal',
    name: 'AdminPortal',
    components: {
      default: AdminDashboardItem
    },
    children: [
      {
        path: '',
        name: 'AdminDashboardItem',
        components: {
          adminPortal: () => import('../components/admin/DashboardItem.vue')
        }
      },
      {
        path: '/cooperative',
        name: 'Cooperative',
        components: {
          adminPortal: () => import('../components/admin/AdminCooperative.vue')
        }
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    components: {
      default: Dashboard
    },
    children: [
      {
        path: '',
        name: 'HomeDashboardItem',
        components: {
          dashboardRouter: HomeDashboardItem
        }
      },
      {
        path: '/users',
        components: {
          dashboardRouter: () => import('../views/UsersView.vue')
        }
      },
      {
        path: '/banks',
        components: {
          dashboardRouter: () => import('../views/BanksView.vue')
        }
      },
      {
        path: '/accounts',
        components: {
          dashboardRouter: () => import('../components/accounts/AccountsItem.vue')
        },
        children: [
          {
            path: '',
            components: {
              accounts: () => import('../components/accounts/AccountsAllMembersData.vue')
            }
          },
          {
            path: '/accounts/:id',
            components: {
              accounts: () => import('../components/accounts/AccountSingleMembers.vue')
            }
          },
          {
            path: '/accounts/refund-transaction/:id',
            components: {
              accounts: () => import('../components/accounts/RefundSavingTransaction.vue')
            }
          },
          {
            path: '/accounts/refund-commission-transaction/:id',
            components: {
              accounts: () => import('../components/accounts/RefundCommissionTransaction.vue')
            }
          }
        ]
      },
      {
        path: '/reports',
        components: {
          dashboardRouter: () => import('../views/ReportsView.vue')
        }
      },
      {
        path: '/billing-details',
        components: {
          dashboardRouter: () => import('../views/BillingDetails.vue')
        }
      },
      {
        path: '/setting-savings',
        components: {
          dashboardRouter: () => import('../components/settings/SavingSettings.vue')
        }
      },
      {
        path: '/setting-loans',
        components: {
          dashboardRouter: import('../components/settings/LoanSettings.vue')
        }
      },
      {
        path: '/group-members',
        components: {
          dashboardRouter: () => import('../views/GroupMembers.vue')
        },
        children: [
          {
            path: '',
            components: {
              members: () => import('../components/membersItem/ListMembers.vue')
            }
          },
          {
            path: '/new-member',
            components: {
              members: () => import('../components/membersItem/NewMembers.vue')
            }
          },
          {
            path: '/update-member',
            components: {
              members: () => import('../components/membersItem/UpdateMembers.vue')
            }
          },
          {
            path: '/group-member-details/:id',
            components: {
              members: () => import('../components/membersItem/MembersDetails.vue')
            }
          }
        ]
      },
      {
        path: '/group-savings',
        components: {
          dashboardRouter: () => import('../views/GroupSavings.vue')
        },
        children: [
          {
            path: '',
            components: {
              savings: () => import('../components/savingsItem/ListSavings.vue')
            }
          },
          {
            path: '/new-saving',
            components: {
              savings: () => import('../components/savingsItem/NewSavings.vue')
            }
          },
          {
            path: '/new-seasonal-shares',
            components: {
              savings: () => import('../components/savingsItem/NewSeasonalShares.vue')
            }
          },
          {
            path: '/update-savings',
            components: {
              savings: () => import('../components/savingsItem/UpdateSavings.vue')
            }
          }
        ]
      },
      {
        path: '/group-loans',
        components: {
          dashboardRouter: () => import('../views/GroupLoans.vue')
        },
        children: [
          {
            path: '',
            components: {
              loans: () => import('../components/loansItem/ListLoans.vue')
            }
          },
          {
            path: '/record-loans-transaction',
            components: {
              loans: () => import('../components/loansItem/NewLoans.vue')
            }
          },
          {
            path: '/record-loans-payment',
            components: {
              loans: () => import('../components/loansItem/NewLoansPayment.vue')
            }
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
