<template>
  <div class="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
    <div class="top-bar row gx-0 align-items-center d-none d-lg-flex">
      <div class="col-lg-6 px-5 text-start">
        <small
          ><i class="fa fa-map-marker-alt text-primary me-2"></i>Kicukiro, Kigali
          , Rwanda</small
        >
        <small class="ms-4"
          ><i class="fa fa-clock text-primary me-2"></i>9.00 am - 9.00 pm</small
        >
      </div>
      <div class="col-lg-6 px-5 text-end">
        <small
          ><i class="fa fa-envelope text-primary me-2"></i
          >ganzatambaheritier24@gmail.com</small
        >
        <small class="ms-4"
          ><i class="fa fa-phone-alt text-primary me-2"></i>+(250)789 326
          245</small
        >
      </div>
    </div>

    <nav
      class="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <a href="#" class="navbar-brand ms-4 ms-lg-0">
        <img src="img/logo-black.svgs" alt="">
        <h1 class="display-5 text-primary m-0">IKIMINA</h1>
      </a>
      <button
        type="button"
        class="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto p-4 p-lg-0">
          <a href="/" class="nav-item nav-link active">Home</a>
          <a href="/about" class="nav-item nav-link">About</a>
          <a href="/services" class="nav-item nav-link">Services</a>
          <a href="/contact" class="nav-item nav-link">Contact</a>
          <a href="/login" class="nav-item nav-link">Login</a>
          <a href="/register" class="nav-item nav-link">Sign Up</a>
        </div>
        <div class="d-none d-lg-flex ms-2">
          <a class="btn btn-light btn-sm-square rounded-circle ms-3" href="">
            <small class="fab fa-facebook-f text-primary"></small>
          </a>
          <a class="btn btn-light btn-sm-square rounded-circle ms-3" href="">
            <small class="fab fa-twitter text-primary"></small>
          </a>
          <a class="btn btn-light btn-sm-square rounded-circle ms-3" href="">
            <small class="fab fa-linkedin-in text-primary"></small>
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>
