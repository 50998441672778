<template>
  <!-- Sale & Revenue Start -->
  <div class="container-fluid pt-4 px-4">
    <h3>Dashboard Summary Transactions</h3>
    <div class="row g-4">
      <div class="col-sm-6 col-xl-2">
        <div
          class="bg-light rounded d-flex align-items-center justify-content-between p-4"
        >
          <!-- <i class="fa fa-chart-line fa-3x text-primary"></i> -->
          <div class="ms-3">
            <p class="mb-2">Total Members</p>
            <h6 class="mb-0">{{ totalMembers }}</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-2">
        <div
          class="bg-light rounded d-flex align-items-center justify-content-between p-4"
        >
          <!-- <i class="fa fa-chart-bar fa-3x text-primary"></i> -->
          <div class="ms-3">
            <p class="mb-2">Savings Amounts</p>
            <h6 class="mb-0">{{ totalSavingsAmount }} RWF</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-2">
        <div
          class="bg-light rounded d-flex align-items-center justify-content-between p-4"
        >
          <!-- <i class="fa fa-chart-bar fa-3x text-primary"></i> -->
          <div class="ms-3">
            <p class="mb-2">Savings Interest</p>
            <h6 class="mb-0">{{ savingInterestAmount }} RWF</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div
          class="bg-light rounded d-flex align-items-center justify-content-between p-4"
        >
          <!-- <i class="fa fa-chart-area fa-3x text-primary"></i> -->
          <div class="ms-3">
            <p class="mb-2">Total Loans Amount</p>
            <h6 class="mb-0">{{ totalLoansAmount }} RWF</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div
          class="bg-light rounded d-flex align-items-center justify-content-between p-4"
        >
          <!-- <i class="fa fa-chart-pie fa-3x text-primary"></i> -->
          <div class="ms-3">
            <p class="mb-2">Total Loans Interest Amount</p>
            <h6 class="mb-0">{{ totalLoansInterestAmount }} RWF</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sale & Revenue End -->

  <!-- Recent Sales Start -->
  <div class="container-fluid pt-4 px-4">
    <div class="bg-light text-center rounded p-4">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <h6 class="mb-0">Recent Transaction</h6>
        <a href="">Show All</a>
      </div>
      <div class="table-responsive">
        <table
          class="table text-start align-middle table-bordered table-hover mb-0"
        >
          <thead>
            <tr class="text-dark">
              <th scope="col">#</th>
              <th scope="col">Date Transaction</th>
              <th scope="col">Transaction Amount</th>
              <th scope="col">Transaction Type</th>
              <th scope="col">Member Names</th>
              <th scope="col">Transaction Description</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(transaction, key) in transactions" :key="key">
              <TdTransaction
                :id="transaction.id"
                :memberNames="transaction.memberNames"
                :transactionDate="transaction.transactionDate"
                :transactionAmount="transaction.transactionAmount"
                :transactionType="transaction.transactionType"
                :transactionReason="transaction.transactionReason"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- Recent Sales End -->
</template>
<script>
import axios from 'axios'
import TdTransaction from '../globalComponents/TdTransaction.vue'
export default {
  components: {
    TdTransaction
  },
  data () {
    return {
      totalMembers: '0',
      totalSavingsAmount: '0',
      totalLoansAmount: '0',
      totalLoansInterestAmount: '0',
      savingInterestAmount: '0',
      transactions: []
    }
  },
  methods: {
    async allTransactions () {
      const companyID = localStorage.getItem('companyID')
      try {
        const response = await axios.get(
          '/api/dashboard-item/' + companyID
        )
        this.transactions = response.data.transactions
        this.totalMembers = response.data.totalMembers
        this.totalSavingsAmount = response.data.totalSavingsAmount
        this.totalLoansAmount = response.data.totalLoansAmount
        this.totalLoansInterestAmount = response.data.loansInterestAmount
        this.savingInterestAmount = response.data.savingInterestAmount
      } catch (error) {
        alert(error.message)
      }
    }
  },
  mounted () {
    this.allTransactions()
  }
}
</script>
