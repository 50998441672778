<template>
  <div class="container-fluid position-relative bg-white d-flex p-0">
    <!-- Spinner Start -->
    <!-- <div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
              <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                  <span class="sr-only">Loading...</span>
              </div>
          </div> -->
    <!-- Spinner End -->

    <!-- Sidebar Start -->
    <div class="sidebar pe-4 pb-3">
      <nav class="navbar bg-light navbar-light">
        <a href="#" class="navbar-brand mx-4 mb-3">
          <h3 class="text-primary">
            <i class="fa fa-hashtag me-2"></i>Admin Portal
          </h3>
        </a>
        <div class="d-flex align-items-center ms-4 mb-4">
          <div class="position-relative">
            <img
              class="rounded-circle"
              src="assets/img/user.jpg"
              alt=""
              style="width: 40px; height: 40px"
            />
            <div
              class="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"
            ></div>
          </div>
          <div class="ms-3">
            <a href="#" class="nav-item nav-link active"><h6 class="mb-0">{{ userName }}</h6></a>
            <span hidden>{{ userID }}</span>
          </div>
        </div>
        <div class="navbar-nav w-100">
          <router-link to="/admin-portal" class="nav-item nav-link"
            ><i class="fa fa-tachometer-alt me-2"></i>Dashboard</router-link
          >
          <router-link to="/cooperative" class="nav-item nav-link"
            ><i class="fa fa-desktop me-2"></i>Cooperatives</router-link
          >
          <router-link to="/cooperative" class="nav-item nav-link"
            ><i class="fa fa-keyboard me-2"></i>Subscriptions</router-link
          >
          <!-- <router-link to="/admin-users" class="nav-item nav-link">
            <i class="fa fa-users me-2"></i> Users</router-link> -->
        </div>
      </nav>
    </div>
    <!-- Sidebar End -->

    <!-- Content Start -->
    <div class="content">
      <!-- Navbar Start -->
      <nav
        class="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0"
      >
        <a href="#" class="navbar-brand d-flex d-lg-none me-4">
          <h2 class="text-primary mb-0"><i class="fa fa-hashtag"></i></h2>
        </a>
        <a href="#" class="sidebar-toggler flex-shrink-0">
          <i class="fa fa-bars"></i>
        </a>
        <form class="d-none d-md-flex ms-4">
          <input
            class="form-control border-0"
            type="search"
            placeholder="Search"
          />
        </form>
        <div class="navbar-nav align-items-center ms-auto">
          <div class="nav-item dropdown">
            <a
              href="#"
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <img
                class="rounded-circle me-lg-2"
                src="assets/img/user.jpg"
                alt=""
                style="width: 40px; height: 40px"
              />
              <span class="d-none d-lg-inline-flex">{{ userName }}</span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0"
            >
              <a href="#" class="dropdown-item">My Profile</a><br />
              <a href="/billing-details" class="dropdown-item"
                >Billing Details</a
              ><br />
              <a href="/company" class="dropdown-item">Switch Account</a><br />
              <a href="#" class="dropdown-item" @click="logoutClick()"
                >Log Out</a
              >
            </div>
          </div>
        </div>
      </nav>
      <!-- Navbar End -->
      <router-view name="adminPortal"></router-view>
    </div>
    <!-- Content End -->

    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"
      ><i class="bi bi-arrow-up"></i
    ></a>
  </div>
</template>
<script>
export default {
  data () {
    return {
      userName: '',
      userID: ''
    }
  },
  methods: {
    requiredData () {
      this.userName = localStorage.getItem('userName')
      this.userID = localStorage.getItem('userID')
    },
    logoutClick () {
      window.location.href = '/'
    }
  },
  mounted () {
    this.requiredData()
  }
}
</script>
