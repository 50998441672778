<template>
  <!-- Navbar Start -->
  <HeaderItem />
  <!-- Navbar End -->

  <!-- Carousel Start -->
  <div class="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
    <div
      id="header-carousel"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="w-100" src="img/home-two.jpg" alt="Image" />
          <div class="carousel-caption">
            <div class="container">
              <div class="row">
                <div class="col-lg-8">
                  <p
                    class="d-inline-block border border-white rounded text-primary fw-semi-bold py-1 px-3 animated slideInDown"
                  >
                    Welcome to IKIMINA
                  </p>
                  <h1 class="display-1 mb-4 animated slideInDown">
                    Your Financial Status Is Our Goal
                  </h1>
                  <a
                    href=""
                    class="btn btn-primary py-3 px-5 animated slideInDown"
                    >Explore More</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img class="w-100" src="img/home-two.jpg" alt="Image" />
          <div class="carousel-caption">
            <div class="container">
              <div class="row">
                <div class="col-lg-8">
                  <p
                    class="d-inline-block border border-white rounded text-primary fw-semi-bold py-1 px-3 animated slideInDown"
                  >
                    Welcome to IKIMINA
                  </p>
                  <h1 class="display-1 mb-4 animated slideInDown">
                    Your Financial Status Is Our Goal
                  </h1>
                  <a
                    href=""
                    class="btn btn-primary py-3 px-5 animated slideInDown"
                    >Explore More</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#header-carousel"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#header-carousel"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  <!-- Carousel End -->

  <!-- About Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-4 align-items-end mb-4">
        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <img class="img-fluid rounded" src="img/logo-white.svg" />
        </div>
        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
          <p
            class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3"
          >
            About Us
          </p>
          <h1 class="display-5 mb-4">
            We Help Our Clients To Grow Their Cooperative
          </h1>
          <p class="mb-4">
            Ikimina Software Is a Fintech software which help Groups of
            cooperatives (Ibimina) to grow their financial status easily and
            Manage Them their information and storing them their data on cloud
            safely
          </p>
          <div class="border rounded p-4">
            <nav>
              <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                <button
                  class="nav-link fw-semi-bold active"
                  id="nav-story-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-story"
                  type="button"
                  role="tab"
                  aria-controls="nav-story"
                  aria-selected="true"
                >
                  Story
                </button>
                <button
                  class="nav-link fw-semi-bold"
                  id="nav-mission-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-mission"
                  type="button"
                  role="tab"
                  aria-controls="nav-mission"
                  aria-selected="false"
                >
                  Mission
                </button>
                <button
                  class="nav-link fw-semi-bold"
                  id="nav-vision-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-vision"
                  type="button"
                  role="tab"
                  aria-controls="nav-vision"
                  aria-selected="false"
                >
                  Vision
                </button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-story"
                role="tabpanel"
                aria-labelledby="nav-story-tab"
              >
                <p>
                  The journey Began with numerous brainstorming sessions and
                  tireless nights, driven by an unwavering dedication to
                  innovation. The team fervently delved into comprehensive
                  market research, venturing into unexplored domains within the
                  fintech sphere. Their objective was to grasp the requirements
                  of diverse communities, pinpointing the shortcomings of
                  conventional financial systems, and conceiving solutions that
                  resonated with their commitment to inclusivity and
                  empowerment.
                </p>
                <p class="mb-0">
                </p>
              </div>
              <div
                class="tab-pane fade"
                id="nav-mission"
                role="tabpanel"
                aria-labelledby="nav-mission-tab"
              >
                <p>
                  The mission of the fintech venture "Ikimina" is to drive innovation and inclusivity within the financial Cooperative. Their journey, fueled by passion and tireless exploration, focuses on redefining financial accessibility. By understanding diverse community needs and transforming traditional systems, Ikimina aims to empower individuals and businesses through technology-driven solutions.
                </p>
                <p class="mb-0">
                  Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et
                  eos labore. Clita erat ipsum et lorem et sit
                </p>
              </div>
              <div
                class="tab-pane fade"
                id="nav-vision"
                role="tabpanel"
                aria-labelledby="nav-vision-tab"
              >
                <p>
                  A vision to drive innovation within the financial Cooperative,
                  transforming traditional processes and services through
                  technological advancements.
                </p>
                <p class="mb-0"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border rounded p-4 wow fadeInUp" data-wow-delay="0.1s">
        <div class="row g-4">
          <div class="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
            <div class="h-100">
              <div class="d-flex">
                <div
                  class="flex-shrink-0 btn-lg-square rounded-circle bg-primary"
                >
                  <i class="fa fa-times text-white"></i>
                </div>
                <div class="ps-3">
                  <h4>No Hidden Cost</h4>
                  <span>"Ikimina: Bridging Finances, Building Bonds"</span>
                </div>
                <div class="border-end d-none d-lg-block"></div>
              </div>
              <div class="border-bottom mt-4 d-block d-lg-none"></div>
            </div>
          </div>
          <div class="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
            <div class="h-100">
              <div class="d-flex">
                <div
                  class="flex-shrink-0 btn-lg-square rounded-circle bg-primary"
                >
                  <i class="fa fa-users text-white"></i>
                </div>
                <div class="ps-3">
                  <h4>Dedicated Team</h4>
                  <span>"Savings Together, Succeed Together: Ikimina Way"</span>
                </div>
                <div class="border-end d-none d-lg-block"></div>
              </div>
              <div class="border-bottom mt-4 d-block d-lg-none"></div>
            </div>
          </div>
          <div class="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
            <div class="h-100">
              <div class="d-flex">
                <div
                  class="flex-shrink-0 btn-lg-square rounded-circle bg-primary"
                >
                  <i class="fa fa-phone text-white"></i>
                </div>
                <div class="ps-3">
                  <h4>24/7 Available</h4>
                  <span>We Make Support anytime you want it !</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->

  <!-- Facts Start -->
  <div class="container-fluid facts my-5 py-5">
    <div class="container py-5">
      <div class="row g-5">
        <div
          class="col-sm-6 col-lg-3 text-center wow fadeIn"
          data-wow-delay="0.1s"
        >
          <i class="fa fa-users fa-3x text-white mb-3"></i>
          <h1 class="display-4 text-white" data-toggle="counter-up">1234</h1>
          <span class="fs-5 text-white">Happy Clients</span>
          <hr class="bg-white w-25 mx-auto mb-0" />
        </div>
        <div
          class="col-sm-6 col-lg-3 text-center wow fadeIn"
          data-wow-delay="0.3s"
        >
          <i class="fa fa-check fa-3x text-white mb-3"></i>
          <h1 class="display-4 text-white" data-toggle="counter-up">1234</h1>
          <span class="fs-5 text-white">Transactions Recorded</span>
          <hr class="bg-white w-25 mx-auto mb-0" />
        </div>
        <div
          class="col-sm-6 col-lg-3 text-center wow fadeIn"
          data-wow-delay="0.5s"
        >
          <i class="fa fa-users-cog fa-3x text-white mb-3"></i>
          <h1 class="display-4 text-white" data-toggle="counter-up">1234</h1>
          <span class="fs-5 text-white">Dedicated Staff</span>
          <hr class="bg-white w-25 mx-auto mb-0" />
        </div>
        <div
          class="col-sm-6 col-lg-3 text-center wow fadeIn"
          data-wow-delay="0.7s"
        >
          <i class="fa fa-award fa-3x text-white mb-3"></i>
          <h1 class="display-4 text-white" data-toggle="counter-up">1234</h1>
          <span class="fs-5 text-white">Awards Achieved</span>
          <hr class="bg-white w-25 mx-auto mb-0" />
        </div>
      </div>
    </div>
  </div>
  <!-- Facts End -->

  <!-- Features Start -->
  <div class="container-xxl feature py-5">
    <div class="container">
      <div class="row g-5 align-items-center">
        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <p
            class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3"
          >
            Why Choosing Us!
          </p>
          <h1 class="display-5 mb-4">Few Reasons Why People Choosing Us!</h1>
          <p class="mb-4">
            Ikimina simplifies group savings by offering a convenient platform
            where cooperative members can collectively save daily, weekly, or
            monthly according to their chosen savings cycle.
          </p>
          <a class="btn btn-primary py-3 px-5" href="">Explore More</a>
        </div>
        <div class="col-lg-6">
          <div class="row g-4 align-items-center">
            <div class="col-md-6">
              <div class="row g-4">
                <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                  <div class="feature-box border rounded p-4">
                    <i class="fa fa-check fa-3x text-primary mb-3"></i>
                    <h4 class="mb-3">Fast Executions</h4>
                    <p class="mb-3">
                     We have ability to promptly process transactions, respond to user requests, and execute financial actions in real-time or with minimal delay.
                    </p>
                    <a class="fw-semi-bold" href=""
                      >Read More <i class="fa fa-arrow-right ms-1"></i
                    ></a>
                  </div>
                </div>
                <div class="col-12 wow fadeIn" data-wow-delay="0.5s">
                  <div class="feature-box border rounded p-4">
                    <i class="fa fa-check fa-3x text-primary mb-3"></i>
                    <h4 class="mb-3">Guide & Support</h4>
                    <p class="mb-3">
                      For new users or clients, Ikimina offers comprehensive onboarding guidance, helping them understand the platform's functionalities, features, and how to use them effectively.
                    </p>
                    <p>
                      This support may be available through various channels like chat, email, or phone and face to face.
                    </p>
                    <a class="fw-semi-bold" href=""
                      >Read More <i class="fa fa-arrow-right ms-1"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 wow fadeIn" data-wow-delay="0.7s">
              <div class="feature-box border rounded p-4">
                <i class="fa fa-check fa-3x text-primary mb-3"></i>
                <h4 class="mb-3">Financial Secure</h4>
                <p class="mb-3">
                  Ikimina utilizes advanced encryption techniques to protect financial data during transmission and storage.
                </p>
                <a class="fw-semi-bold" href=""
                  >Read More <i class="fa fa-arrow-right ms-1"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Features End -->

  <!-- Callback Start -->
  <div class="container-fluid callback my-5 pt-5">
    <div class="container pt-5">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div
            class="bg-white border rounded p-4 p-sm-5 wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div
              class="text-center mx-auto wow fadeInUp"
              data-wow-delay="0.1s"
              style="max-width: 600px"
            >
              <p
                class="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3"
              >
                Get In Touch
              </p>
              <h1 class="display-5 mb-5">Request A Call-Back</h1>
            </div>
            <div class="row g-3">
              <div class="col-sm-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="Your Name"
                  />
                  <label for="name">Your Name</label>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-floating">
                  <input
                    type="email"
                    class="form-control"
                    id="mail"
                    placeholder="Your Email"
                  />
                  <label for="mail">Your Email</label>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="mobile"
                    placeholder="Your Mobile"
                  />
                  <label for="mobile">Your Mobile</label>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="subject"
                    placeholder="Subject"
                  />
                  <label for="subject">Subject</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a message here"
                    id="message"
                    style="height: 100px"
                  ></textarea>
                  <label for="message">Message</label>
                </div>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-primary w-100 py-3" type="submit">
                  Submit Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Callback End -->

  <FooterItem />
</template>
<script>
import HeaderItem from '../components/homeItem/HeaderItem.vue'
import FooterItem from '../components/FooterItem.vue'
export default {
  components: {
    HeaderItem,
    FooterItem
  }
}
</script>
