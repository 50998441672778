import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Toast from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

const app = createApp(App)
console.log(app)
const options = {
  // You can set your default options here
}

app.use(Toast, options)
// app.use(VModal, {
//   dynamicDefaults: {
//     draggable: true,
//     resizable: true,
//     height: 500
//   }
// },
// { dialog: true })

axios.defaults.baseURL = 'http://localhost:8001'
axios.defaults.headers.post['Content-Type'] = 'application/json'

createApp(App).use(store).use(router).mount('#app')
